import React from 'react';
import './subscription.css'

export default function SubscriptionCard({ plan, setSelectedPlan, selectedPlan }) {
  return (
    <div className="subscription-card">
      <div className="title">
        {plan.name}
      </div>
      <div className="price">
        from Rs.{plan.price}/-
      </div>
      <div className="total-price">
        + GST as applicable ({plan.totalPrice}/-)
      </div>
      <div style={{height:'60px', minHeight:'60px'}}>
        {plan?.benefits?.map((benefit, index) => (
          <p key={index} className="">
            {benefit}
          </p>
        ))}
      </div>
      <hr />
      <div style={{  marginTop: '13vh' }}>
        <div
          className={`select-button ${plan === selectedPlan ? 'selected' : ''}`}
          onClick={() => {
            console.log("clicked")
            setSelectedPlan(plan)}
          }
        >
          {plan === selectedPlan ? 'Selected' : 'Select This Plan'}
        </div>
        <div className="terms">
          Terms and conditions apply
        </div>
      </div>
    </div>
  );
}
