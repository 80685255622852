import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Paragraph } from 'app/components/Typography';
import { useState, useEffect } from 'react';
import axios from 'axios';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));

const GuestTable = () => {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const [Bookings, Setbookings] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [Bookingid, setBookingid] = useState('');
  const [Status, setStatus] = useState('');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fetchData = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      console.log('this is token', accessToken);
      const response = await axios.get(
        'https://api-dev.activetlife.com/api/hotel-management/property-owner/bookings?page=1&limit=10',
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const products = response.data.items.bookings;
      console.log(products);

      if (products) {
        Setbookings(products);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  // http://localhost:6000/api/hotel-management/hotel-booking/65a562d585fbbde4fdbb57fd

  const handleUpdateRoom = async (Bookid) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const url = `https://api-dev.activetlife.com/api/hotel-management/hotel-booking/${Bookid}`;

    try {
      const status = {
        status: Status,
      };

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(status),
      });

      if (response.ok) {
        console.log(`Room with id ${Bookid} successfully updated`);
      } else {
        console.error('Error updating room:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error updating room:', error.message);
    }
    fetchData();
    setOpenModal(false);
  };

  return (
    <div style={{ width: '98%', display: 'flex', marginLeft: 15, marginTop: 10 }}>
      <Card elevation={20} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Title>Booking List</Title>
          {/* <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{ textTransform: 'uppercase' }}
            onClick={handleOpenModal}
          >
            Add Booking
          </Button> */}
        </CardHeader>

        <Box overflow="auto">
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Update Status</DialogTitle>
            <DialogContent>
              <FormControl sx={{ m: 1, minWidth: 320 }} size="medium">
                <InputLabel>Change Status</InputLabel>
                <Select value={Status} label="Change Status" onChange={handleChange}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'ongoing'}>Ongoing</MenuItem>
                  <MenuItem value={'completed'}>completed</MenuItem>
                  <MenuItem value={'cancelled'}>cancelled</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleUpdateRoom(Bookingid)}
                color="primary"
                variant="contained"
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        <Box overflow="auto">
          <ProductTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 10 }} colSpan={4}>
                  Guest Details
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={3}>
                  Hotel
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={3}>
                  Check-in/Check-out
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={1}>
                  Guest
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={1}>
                  Status
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={1}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Bookings?.map((product, index) => (
                <TableRow key={index} hover>
                  <TableCell colSpan={4} align="left" sx={{ px: 0, textTransform: 'capitalize' }}>
                    <Box display="flex" alignItems="center">
                      <Avatar src={product.imgUrl} />
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Paragraph sx={{ m: 0, ml: 4 }}>{product.user.fullName}</Paragraph>
                        <Paragraph sx={{ m: 0, ml: 2 }}>({product.user.email})</Paragraph>
                        <Paragraph sx={{ m: 0, ml: 2 }}>({product.user.mobileNumber})</Paragraph>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="left" colSpan={3} sx={{ px: 0, textTransform: 'capitalize' }}>
                    {product.hotel.name}
                  </TableCell>

                  <TableCell sx={{ px: 0 }} align="left" colSpan={3}>
                    {formatDate(product.checkIn)} To {formatDate(product.checkOut)}
                  </TableCell>
                  <TableCell sx={{ px: 0 }} align="left" colSpan={1}>
                    {product.guests}
                  </TableCell>
                  <TableCell sx={{ px: 0 }} align="left" colSpan={1}>
                    <Small
                      bgcolor={
                        product.status === 'ongoing'
                          ? bgSecondary
                          : product.status === 'completed'
                          ? bgPrimary
                          : product.status === 'cancelled'
                          ? bgError
                          : ''
                      }
                    >{product.status}</Small>
                  </TableCell>

                  <TableCell sx={{ px: 0 }} colSpan={1}>
                    <IconButton
                      onClick={() => {
                        setOpenModal(true);
                        setBookingid((prevId) => product._id);
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </div>
  );
};

const productList = [
  {
    imgUrl: '/assets/images/products/headphone-2.jpg',
    name: 'earphone',
    price: 100,
    available: 15,
  },
  {
    imgUrl: '/assets/images/products/headphone-3.jpg',
    name: 'earphone',
    price: 1500,
    available: 30,
  },
  {
    imgUrl: '/assets/images/products/iphone-2.jpg',
    name: 'iPhone x',
    price: 1900,
    available: 35,
  },
  {
    imgUrl: '/assets/images/products/iphone-1.jpg',
    name: 'iPhone x',
    price: 100,
    available: 0,
  },
  {
    imgUrl: '/assets/images/products/headphone-3.jpg',
    name: 'Head phone',
    price: 1190,
    available: 5,
  },
];

export default GuestTable;
