export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'home',
    svgIconUrl: '/assets/novotel/Dashboard.svg',
  },
  {
    name: 'My Booking',
    path: '/guest',
    icon: 'dashboard',
    svgIconUrl: '/assets/novotel/Report.svg',
  },
  {
    name: 'Hotels',
    path: '/rooms',
    icon: 'dashboard',
    svgIconUrl: '/assets/novotel/Rooms.svg',
  },
  // {
  //   name: 'Users',
  //   path: '/users',
  //   icon: 'dashboard',
  //   svgIconUrl: '/assets/novotel/Deals.svg',
  // },
];
