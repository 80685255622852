import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  TextField,
  Typography,
  Divider,
  Paper,
  InputBase,
} from '@mui/material';
import { Paragraph } from 'app/components/Typography';
import { useState, useEffect } from 'react';
import axios from 'axios';

import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));

const RoomTable = () => {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const [productList, setProductList] = useState([]);
  const [roomList, setRoomList] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openroomModal, setOpenroomModal] = useState(false);
  const [openaddroomModal, setOpenaddroomModal] = useState(false);
  const [opendeletehotel, setOpendeletehotel] = useState(false);
  const [opendeleteroom, setOpendeleteroom] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateroomModal, setOpenUpdateroomModal] = useState(false);
  const [prevHotelData, setPrevHotelData] = useState([]);
  const [room, setRoom] = useState([]);
  const [prevRoomData, setPrevRoomData] = useState([]);

  const [id, setid] = useState('');
  const [roomid, setroomid] = useState('');
  const [hotelid, sethotelid] = useState('');
  const [hotel,sethotel]=useState([])
  const [name, setName] = useState('');
  const [hotelData, setHotelData] = useState({
    name: '',
    location: {
      type: 'Point',
      coordinates: [17.6868, 83.2185],
    },
    address: '',
    images: [
      {
        URL: 'https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-01.jpg', // required
        thumbnail: false, // optional
        format: 'image/jpg', // required
        description: '', // optional
      },
      {
        URL: 'https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-02.jpg', // required
        thumbnail: true, // optional
        format: 'image/jpg', // required
        description: '', // optional
      },
      {
        URL: 'https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-03.jpg', // required
        thumbnail: false, // optional
        format: 'image/jpg', // required
        description: '', // optional
      },
      {
        URL: 'https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-04.jpg', // required
        thumbnail: false, // optional
        format: 'image/jpg', // required
        description: '', // optional
      },
    ],
    description: '',
    minimumPrice: 0,
    details: [
      // this are hotel facilities
      {
        URL: 'url image 41', // optional, if you are handling from front-end
        label: 'City view',
      },
      {
        URL: 'url image 41', // optional, if you are handling from front-end
        label: 'Garden',
      },
      {
        URL: 'url image 41', // optional, if you are handling from front-end
        label: 'Outdoor swimming pool',
      },
      {
        URL: 'url image 41', // optional, if you are handling from front-end
        label: 'BBQ facilities',
      },
      {
        URL: 'url image 42',
        label: 'Fitness Center',
      },
    ],
  });

  // ADD ROOM
  const [roomData, setRoomData] = useState({
    name: '',
    description: '',
    images:  [
      {
          "URL": "https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-room-11.jpg",
          "thumbnail": true,
          "format": "image/jpg",
          "description": "",
          "_id": "65a4eff07c297d48b62aa1b7",
          "createdAt": "2024-01-15T08:42:24.917Z",
          "updatedAt": "2024-01-15T08:42:24.917Z"
      },
      {
          "URL": "https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-room-12.jpg",
          "thumbnail": false,
          "format": "image/jpg",
          "description": "",
          "_id": "65a4eff07c297d48b62aa1b8",
          "createdAt": "2024-01-15T08:42:24.917Z",
          "updatedAt": "2024-01-15T08:42:24.917Z"
      },
      {
          "URL": "https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-room-13.jpg",
          "thumbnail": false,
          "format": "image/jpg",
          "description": "",
          "_id": "65a4eff07c297d48b62aa1b9",
          "createdAt": "2024-01-15T08:42:24.917Z",
          "updatedAt": "2024-01-15T08:42:24.917Z"
      },
      {
          "URL": "https://activet-life-public-images.s3.ap-south-1.amazonaws.com/hotel-management/novotel/novotel-room-14.jpg",
          "thumbnail": false,
          "format": "image/jpg",
          "description": "",
          "_id": "65a4eff07c297d48b62aa1ba",
          "createdAt": "2024-01-15T08:42:24.917Z",
          "updatedAt": "2024-01-15T08:42:24.917Z"
      }
  ],
    details: [
      {
        URL: '', // Optional image URL from front-end
        label: '1 single bed',
      },
      // ... other detail objects
    ],
    facilities: [
      {
        URL: '', // Optional image URL from front-end
        label: 'Sea view',
      },
      // ... other facility objects
    ],
    price: {
      amountPerDay: 11499,
      discount: 499,
      guests: 1,
      extraChargePerGuests: 2500,
      maximumGuests: 2,
    },
    extras: [
      {
        title: 'Good Breakfast',
        description: ['Extra charges'],
      },
      // ... other extra objects
    ],
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseaddroomModal = () => {
    setOpenaddroomModal(false);
  };
  const handleOpenroomModal = (productid) => {
    setOpenroomModal(true);
    setid(productid);
  };

  const handleCloseroomModal = () => {
    setOpenroomModal(false);
  };

  const fetchData = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      const response = await axios.get(
        'https://api-dev.activetlife.com/api/hotel-management/property-owner/hotels?page=1&limit=10',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const products = response.data;

      setProductList(products?.items?.hotels);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for add room

  const handleChangeroom = (field, value) => {
    setRoomData({ ...roomData, [field]: value });
  };

  const handleAddRoom = async (hotelId) => {

    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const roomDataWithHotelId = {
      ...roomData,
      hotel: hotelId,
    };

    const url = 'https://api-dev.activetlife.com/api/hotel-management/hotel-room';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(roomDataWithHotelId),
      });

      if (response.ok) {
        fetchroomData();
        console.log('Room data successfully sent to the server');
      } else {
        console.error(
          'Error sending room data to the server:',
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error('Error sending room data:', error.message);
    }

    handleCloseaddroomModal();
  };

  const fetchroomData = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      const response = await axios.get(
        `https://api-dev.activetlife.com/api/hotel-management/property-owner/${id}/rooms?page=1&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const products = response.data;

      setRoomList(products?.items?.rooms);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchroomData();
  }, [id]);


  const fillterdata = roomList.filter((room) =>
    room.name.toLowerCase().includes(name.toLowerCase())
  );

  const handleChange = (field, value) => {
    setHotelData({ ...hotelData, [field]: value });
  };

  const Handlesubmithotel = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const url = 'https://api-dev.activetlife.com/api/hotel-management/hotel';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(hotelData),
      });

      if (response.ok) {
        fetchData();
        console.log('Hotel data successfully sent to the server');
      } else {
        console.error(
          'Error sending hotel data to the server:',
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error('Error sending hotel data:', error.message);
    }

    setOpenModal(false);
  };

  // FOR HOTEL DELETE

  const handleDeleteHotel = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const url = `https://api-dev.activetlife.com/api/hotel-management/hotel/${id}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Hotel with id successfully deleted`);
      } else {
        console.error('Error deleting hotel:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error deleting hotel:', error.message);
    }
    setOpendeletehotel(false);
    fetchData();
  };


  // for delete room
  const handleDeleteroom = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const url = `https://api-dev.activetlife.com/api/hotel-management/hotel-room/${roomid}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Hotel with id successfully deleted`);
      } else {
        console.error('Error deleting hotel:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error deleting hotel:', error.message);
    }
    setOpendeleteroom(false);
    fetchroomData();
  };



// Update Hotel 

const handleUpdateChange = (field, value) => {
  sethotel((prevHotel) => ({
    ...prevHotel,
    [field]: value,
  }));
};

const handleOpenUpdateModal = (hotelId) => {
    setOpenUpdateModal(true);
    sethotelid(hotelId);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  const handleUpdateHotel = async (updateid) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in local storage');
      return;
    }

    const url = `https://api-dev.activetlife.com/api/hotel-management/hotel/${updateid}`;

    try {
      const updatedFields = {};

      Object.keys(hotel).forEach((field) => {
        if (hotel[field] !== prevHotelData[field]) {
          updatedFields[field] = hotel[field];
        }
      }); 
      const response = await fetch(url, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedFields),
      });

      if (response.ok) {
        console.log(`Hotel with id ${id} successfully updated`);
        fetchData(); 
      } else {
        console.error(
          'Error updating hotel:',
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error('Error updating hotel:', error.message);
    }

    handleCloseUpdateModal();
  };




// get hotel by id 

const fetchHotelById = async (hotelId) => {
  try {
    const hotelResponse = await axios.get(
      `https://api-dev.activetlife.com/api/hotel-management/hotel/${hotelId}`
    );

    const hotelToUpdate = hotelResponse.data.hotel;

    if (hotelToUpdate) {
      sethotel(hotelToUpdate);
      setPrevHotelData(hotelToUpdate)
    } else {
      console.error('Hotel not found with id:', hotelId);
      return null;
    }
  } catch (error) {
    console.error('Error fetching hotel details:', error.message);
    return null;
  }
};

useEffect(() => {
  fetchHotelById(hotelid);
}, [hotelid]);


// get room by id 

const fetchRoomById = async (roomId) => {
  console.log(roomId)
  const accessToken = window.localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('Access token not found in local storage');
    return;
  }


  try {
    const roomResponse = await axios.get(
      `https://api-dev.activetlife.com/api/hotel-management/hotel-room/${roomId}`
    );
    
    const RoomResponse = roomResponse.data.hotelRoom;
    if (RoomResponse) {
      setRoom(RoomResponse);
      setPrevRoomData(RoomResponse);
    } else {
      console.error('Failed to fetch room data:', roomResponse.status, roomResponse.statusText);
    }
  } catch (error) {
    console.error('Error fetching room data:', error.message);
  }
};

useEffect(() => {
  fetchRoomById(roomid);
}, [roomid]);


// update room 

const handleUpdateroomChange = (field, value) => {
  setRoom((prevHotel) => ({
    ...prevHotel,
    [field]: value,
  }));
}; 

const handleUpdateRoom = async (roomId) => {
  const accessToken = window.localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('Access token not found in local storage');
    return;
  }

  const url = `https://api-dev.activetlife.com/api/hotel-management/hotel-room/${roomId}`;

  try {
    const updatedroomFields = {};

    Object.keys(room).forEach((field) => {
      if (room[field] !== prevRoomData[field]) {
        updatedroomFields[field] = room[field];
      }
    }); 

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(updatedroomFields),
    });

    if (response.ok) {
      console.log(`Room with id ${roomId} successfully updated`);
    } else {
      console.error('Error updating room:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('Error updating room:', error.message);
  }
  fetchroomData();
  setOpenUpdateroomModal(false);
};




  return (
    <div style={{ width: '98%', display: 'flex', marginLeft: 15, marginTop: 10 }}>
      <Card elevation={20} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Title>My Hotels</Title>
          <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{ textTransform: 'uppercase' }}
            onClick={handleOpenModal}
          >
            Add Hotel
          </Button>
        </CardHeader>

        <Box overflow="auto">
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Add Hotel</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Hotel Name"
                type="text"
                fullWidth
                value={hotelData.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Hotel Address"
                type="text"
                fullWidth
                value={hotelData.address}
                onChange={(e) => handleChange('address', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Hotel Description"
                type="text"
                fullWidth
                value={hotelData.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Price"
                type="number"
                fullWidth
                value={hotelData.minimumPrice}
                onChange={(e) => handleChange('minimumPrice', e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <Button onClick={Handlesubmithotel} color="primary" variant="contained">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Box>


        <Box overflow="auto">
          <Dialog open={openUpdateModal} onClose={()=>setOpenUpdateModal(false)}>
            <DialogTitle>Update Hotel</DialogTitle>
            <DialogContent>
              <TextField
               focused
                margin="dense"
                label='Hotel Name'
                type="text"
                fullWidth
                value={hotel.name}
                onChange={(e) => handleUpdateChange('name', e.target.value)}
              />
              <TextField
              focused
                margin="dense"
                type="text"
                label='Hotel Address'
                fullWidth
                value={hotel.address}
                onChange={(e) => handleUpdateChange('address', e.target.value)}
              />
              <TextField
              focused
                margin="dense"
                type="text"
                label='Hotel description'
                fullWidth
                value={hotel.description}
                onChange={(e) => handleUpdateChange('description', e.target.value)}
              />
              <TextField
              focused
                margin="dense"
                type="number"
                label='Hotel price'
                fullWidth
                value={hotel.minimumPrice}
                onChange={(e) => handleUpdateChange('minimumPrice', e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUpdateModal} color="primary">
                Cancel
              </Button>
              <Button onClick={()=>handleUpdateHotel(hotelid)} color="primary" variant="contained">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        <Box overflow="auto">
          <Dialog open={opendeletehotel} onClose={() => setOpendeletehotel(false)}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>Are you sure you want to delete this hotel?</DialogContent>
            <DialogActions>
              <Button onClick={() => setOpendeletehotel(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteHotel} color="secondary" variant="contained">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        {/* delete room  */}
        <Box overflow="auto">
          <Dialog open={opendeleteroom} onClose={() => setOpendeleteroom(false)}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>Are you sure you want to delete this Room?</DialogContent>
            <DialogActions>
              <Button onClick={() => setOpendeleteroom(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteroom} color="secondary" variant="contained">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        <Box overflow="auto">
          <Dialog open={openaddroomModal} onClose={() => setOpenaddroomModal(false)}>
            <DialogTitle>Add Room</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Room Name"
                type="text"
                fullWidth
                value={roomData.name}
                onChange={(e) => handleChangeroom('name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Room Description"
                type="text"
                fullWidth
                value={roomData.description}
                onChange={(e) => handleChangeroom('description', e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenaddroomModal(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleAddRoom(id)} color="primary" variant="contained">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Box>


        {/* for update room  */} 
        <Box overflow="auto">
          <Dialog open={openUpdateroomModal} onClose={() => setOpenUpdateroomModal(false)}>
            <DialogTitle>Update Room</DialogTitle>
            <DialogContent>
              <TextField
                focused
                margin="dense"
                label="Room Name"
                type="text"
                fullWidth
                value={room.name}
                onChange={(e) => handleUpdateroomChange('name', e.target.value)}
              />
              <TextField
                focused
                margin="dense"
                label="Room Description"
                type="text"
                fullWidth
                value={room.description}
                onChange={(e) => handleUpdateroomChange('description', e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenUpdateroomModal(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleUpdateRoom(roomid)} color="primary" variant="contained">
               Update
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

   

        <Box overflow="auto">
          <Dialog open={openroomModal} onClose={handleCloseroomModal}>
            <DialogTitle
              sx={{
                pt: 4,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              Room Details
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300, ml: 2 }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search rooms"
                  inputProps={{ 'aria-label': 'search rooms' }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              {roomList.length > 0 && (
                <Button onClick={() => setOpenaddroomModal(true)} sx={{ ml: 1 }} variant="contained">
                  Add Room
                </Button>
              )}
            </DialogTitle>
            <DialogContent>
              {fillterdata.length > 0 ? (
                <Box>
                  {fillterdata?.map((room, key) => (
                    <Box key={key}>
                      <Divider sx={{ mt: 4 }}></Divider>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ pb: 2, pt: 2 }} variant="h5">
                          {room.name}
                        </Typography>
                        <div>
                          <IconButton onClick={() => {
                             setOpenUpdateroomModal(true);
                             setroomid((prevId) => room._id);
                        }}>
                            <Icon color="primary">edit</Icon>
                          </IconButton>
                          <IconButton onClick={() => {
                             setOpendeleteroom(true);
                             setroomid((prevId) => room._id);
                        }}>
                            <Icon color="error">delete</Icon>
                          </IconButton>
                        </div>
                      </div>
                      {room?.images.map((image, innerKey) => (
                        <img
                          key={innerKey}
                          style={{ height: 150, width: 130, padding: 2 }}
                          src={image?.URL}
                          alt="imagess"
                        />
                      ))}
                      <Typography sx={{ py: 2, width: '100%' }}>{room.description}</Typography>
                      <Box sx={{ py: 2, ml: 0.5 }}>
                        <Small sx={{ p: 1, mb: 2 }} bgcolor={bgError}>
                          Price {room?.price?.amountPerDay}
                        </Small>
                        <Small sx={{ p: 1, mb: 2, ml: 0.5 }} bgcolor={bgSecondary}>
                          Discount {room?.price?.discount}
                        </Small>
                        <Small sx={{ p: 1, mb: 2, ml: 0.5 }} bgcolor={bgPrimary}>
                          Charges {room?.price?.extraChargePerGuests}
                        </Small>
                      </Box>
                      {room?.facilities.map((faciliti, innKey) => (
                        <Small sx={{ p: 1, ml: 0.5 }} bgcolor={bgPrimary} key={innKey}>
                          {faciliti.label}
                        </Small>
                      ))}
                    </Box>
                  ))}
                </Box>
              ) : (
                <>
                  {roomList.length > 0 ? (
                    <Box>
                      {roomList?.map((room, key) => (
                        <Box key={key}>
                          <Divider sx={{ mt: 4 }}></Divider>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ pb: 2, pt: 2 }} variant="h5">
                              {room.name}
                            </Typography>
                            <div>
                              <IconButton onClick={() => {
                             setOpenUpdateroomModal(true);
                             setroomid((prevId) => room._id);
                        }}>
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton onClick={() => {
                             setOpendeleteroom(true);
                             setroomid((prevId) => room._id);
                        }}>
                                <Icon 
                                 color="error">delete</Icon>
                              </IconButton>
                            </div>
                          </div>
                          {room?.images.map((image, innerKey) => (
                            <img
                              key={innerKey}
                              style={{ height: 150, width: 130, padding: 2 }}
                              src={image?.URL}
                              alt="imagess"
                            />
                          ))}
                          <Typography sx={{ py: 2 }}>{room.description}</Typography>
                          <Box sx={{ py: 2, ml: 0.5 }}>
                            <Small sx={{ p: 1, mb: 2 }} bgcolor={bgError}>
                              Price {room?.price?.amountPerDay}
                            </Small>
                            <Small sx={{ p: 1, mb: 2, ml: 0.5 }} bgcolor={bgSecondary}>
                              Discount {room?.price?.discount}
                            </Small>
                            <Small sx={{ p: 1, mb: 2, ml: 0.5 }} bgcolor={bgPrimary}>
                              Charges {room?.price?.extraChargePerGuests}
                            </Small>
                          </Box>
                          {room?.facilities.map((faciliti, innKey) => (
                            <Small sx={{ p: 1, ml: 0.5 }} bgcolor={bgPrimary} key={innKey}>
                              {faciliti.label}
                            </Small>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        p: 15,
                      }}
                    >
                      <Typography sx={{ pb: 2 }}>No Rooms Added</Typography>
                      <Button
                        onClick={() => setOpenaddroomModal(true)}
                        sx={{ mt: 2 }}
                        variant="contained"
                      >
                        Add Room
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseroomModal} color="primary">
                Cancel
              </Button>
              {/* <Button onClick={handleCloseroomModal} color="primary" variant="contained">
                Add
              </Button> */}
            </DialogActions>
          </Dialog>
        </Box>

        <Box overflow="auto">
          <ProductTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 3 }} colSpan={4}>
                  Name
                </TableCell>
                <TableCell sx={{ px: 8 }} colSpan={2}>
                  Address
                </TableCell>
                <TableCell sx={{ px: 10 }} colSpan={2}>
                  Action
                </TableCell>
                <TableCell sx={{ px: 2 }} colSpan={1}>
                  Rooms
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {productList.map((product, index) => (
                <TableRow key={index} hover>
                  <TableCell colSpan={4} align="left" sx={{ px: 0, textTransform: 'capitalize' }}>
                    <Box display="flex" alignItems="center">
                      <img style={{ height: 40, width: 40 }} src={product.images[0].URL} />
                      <Paragraph sx={{ m: 0, ml: 4 }}>{product.name}</Paragraph>
                    </Box>
                  </TableCell>

                  <TableCell align="center" colSpan={2} sx={{ px: 0, textTransform: 'capitalize' }}>
                    {product.address}
                  </TableCell>

                  <TableCell sx={{ px: 8 }} align="left" colSpan={2}>
                    <IconButton onClick={()=>handleOpenUpdateModal(product._id)}>
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton>
                      <Icon
                        onClick={() => {
                          setOpendeletehotel(true);
                          setid((prevId) => product._id);
                        }}
                        color="error"
                      >
                        delete
                      </Icon>
                    </IconButton>

                    {/* {product.available ? (
                      product.available < 20 ? (
                        <Small bgcolor={bgSecondary}>{product.available} available</Small>
                      ) : (
                        <Small bgcolor={bgPrimary}>in stock</Small>
                      )
                    ) : (
                      <Small bgcolor={bgError}>out of stock</Small>
                    )} */}
                  </TableCell>

                  <TableCell sx={{ px: 0, display: 'flex' }} rowSpan={2}>
                    <Button
                      onClick={() => handleOpenroomModal(product._id)}
                      color="primary"
                      variant="contained"
                    >
                      Rooms
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </div>
  );
};

// const productList = [
//   {
//     imgUrl: '/assets/images/products/headphone-2.jpg',
//     name: 'earphone',
//     price: 100,
//     available: 15,
//   },
//   {
//     imgUrl: '/assets/images/products/headphone-3.jpg',
//     name: 'earphone',
//     price: 1500,
//     available: 30,
//   },
//   {
//     imgUrl: '/assets/images/products/iphone-2.jpg',
//     name: 'iPhone x',
//     price: 1900,
//     available: 35,
//   },
//   {
//     imgUrl: '/assets/images/products/iphone-1.jpg',
//     name: 'iPhone x',
//     price: 100,
//     available: 0,
//   },
//   {
//     imgUrl: '/assets/images/products/headphone-3.jpg',
//     name: 'Head phone',
//     price: 1190,
//     available: 5,
//   },
// ];

export default RoomTable;
